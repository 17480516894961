import React, { ReactElement, FC } from "react"
import { Row, Col, Typography, Button } from "antd"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { getPrice } from "../../global/utils/string"
import { I_Store } from "../../global/interfaces"
import { LinkTarget } from "../../global/enums"

import "./stores-result.less"

const { Text } = Typography
interface I_Props {
  result: any
  stores: I_Store[]
  titleSearch: string
  authorSearch: string
  isbnSearch: string
  notAvailable: string
  country: string
}

const StoresResultComponent: FC<I_Props> = ({
  result,
  stores,
  authorSearch,
  isbnSearch,
  notAvailable,
  country,
}: I_Props): ReactElement => {
  return (
    <Row className="stores-result">
      <Col xs={8} lg={3} className="stores-result__cover">
        <img src={result.image} width="80%" height="100%" />
      </Col>
      <Col xs={16} lg={21} className="stores-result__details">
        <div className="stores-result__title">
          <Text>{result.title}</Text>
        </div>
        <div className="stores-result__isbn">
          <Text strong>{isbnSearch}10: </Text>
          <Text>{result.isbn10}</Text>
          <Text disabled> | </Text>
          <Text strong>{isbnSearch}13: </Text>
          <Text>{result.isbn13}</Text>
        </div>
        {result.author && result.author.length && (
          <div className="stores-result__author">
            <Text strong>{authorSearch} </Text>
            <Text>{result.author}</Text>
          </div>
        )}
      </Col>
      {stores.map(({ id, name, logo }: I_Store) => (
        <Col xs={12} md={6} key={id} className="stores-result__store">
          {result[name] && result[name].price ? (
            <div
              className="stores-result__link"
              onClick={() => {
                trackCustomEvent({
                  category: `Buy Book Button - ${name}`,
                  action: "Click",
                  label: result[name].link,
                })
                window.open(result[name].link, LinkTarget.Blank)
              }}
            >
              <img
                src={logo.url}
                alt={name}
                width="80%"
                className="stores-result__logo"
              />
              <Button type="primary">
                {getPrice(result[name].price, country)}
              </Button>
            </div>
          ) : (
            <>
              <img
                src={logo.url}
                alt={name}
                width="80%"
                className="stores-result__logo"
              />
              <Button disabled>{notAvailable}</Button>
            </>
          )}
        </Col>
      ))}
    </Row>
  )
}

export default StoresResultComponent
