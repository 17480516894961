import React, { ReactElement, FC } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Container from "../components/container/container"
import SearchResults from "../components/search-results/search-results"
import SEO from "../components/seo/seo"
import CountryLanguageBanner from "../components/country-language-banner/country-language-banner"
import CookiesBanner from "../components/cookies-banner/cookies-banner"
import {
  I_CountryLanguage,
  I_CookiesBanner,
  I_Navigation,
  I_Footer,
  I_Store,
  I_SEO,
  I_Location,
} from "../global/interfaces"

interface I_Strapi {
  countryLanguage: I_CountryLanguage
  navigation: I_Navigation
  footer: I_Footer
  stores: I_Store[]
  cookiesBanner: I_CookiesBanner
}

interface I_PageContext {
  title: string
  text: string
  titleSearch: string
  authorSearch: string
  isbnSearch: string
  notAvailable: string
  noResults: string
  agregateTitle: string
  agregateText: string
  booksListText: string
  seo: I_SEO
  baseUrl: string
}

interface I_Props {
  pageContext: I_PageContext
  data: {
    strapi: I_Strapi
  }
  location: I_Location
}

const Results: FC<I_Props> = ({
  pageContext,
  data,
  location,
}: I_Props): ReactElement => {
  const {
    countryLanguage,
    navigation,
    footer,
    stores,
    cookiesBanner,
  } = data.strapi
  const { baseUrl } = pageContext

  return (
    <>
      <SEO {...pageContext.seo} baseUrl={baseUrl} />
      <CookiesBanner {...cookiesBanner} />
      <CountryLanguageBanner {...countryLanguage} baseUrl={baseUrl} />
      <Layout
        navigation={navigation}
        footer={footer}
        location={location}
        baseUrl={baseUrl}
      >
        <Container>
          <SearchResults {...pageContext} stores={stores} />
        </Container>
      </Layout>
    </>
  )
}

export default Results

export const pageQuery = graphql`
  query ResultsPageQuery($locale: String) {
    strapi {
      countryLanguage(locale: $locale) {
        text
        countryOptions {
          label
          options {
            id
            text
            value
          }
        }
        languageOptions {
          label
          options {
            id
            text
            value
          }
        }
        button {
          text
        }
      }
      navigation(locale: $locale) {
        links {
          id
          link
          target
          text
        }
      }
      stores(locale: $locale) {
        id
        link {
          text
          link
          id
          target
        }
        logo {
          url
        }
        name
      }
      footer(locale: $locale) {
        copyright
        linkList {
          id
          title
          links {
            id
            text
            link
            target
          }
        }
      }
      cookiesBanner(locale: $locale) {
        title
        text
        acceptButton {
          text
        }
      }
    }
  }
`
