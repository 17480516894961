import React, { ReactElement, FC } from "react"
import { Typography, Button, Collapse } from "antd"
import ReactMarkdown from "react-markdown"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { I_Store } from "../../global/interfaces"
import { LinkTarget } from "../../global/enums"
import { getPrice } from "../../global/utils/string"

import "./aggregate-results.less"

const { Title, Text } = Typography
const { Panel } = Collapse

const STORES = {
  amazonEs:
    "https://www.amazon.es/gp/aws/cart/add.html?AssociateTag=lettesall-21",
  amazonIt:
    "https://www.amazon.it/gp/aws/cart/add.html?AssociateTag=lettitall-21",
  casaDelLibro:
    "https://pdt.tradedoubler.com/click?a(3259941)p(315969)product(42591)ttid(3)url(https://www.casadellibro.com/compra/pasarelaCompra?ecompra=",
  imosver: "https://www.imosver.com/es/cesta.php?ean=",
}

interface I_Props {
  title: string
  text: string
  country: string
  results: any
  notAvailable: string
  stores: I_Store[]
  booksListText: string
}

interface I_TotalButtonProps {
  store: string
  results: any
  notAvailable: string
  country: string
}

const getLink = (storeKey: string): null | string => {
  const index = Object.keys(STORES).findIndex(store => store === storeKey)
  if (index !== -1) {
    return Object.values(STORES)[index]
  }

  return null
}

const TotalButton: FC<I_TotalButtonProps> = ({
  store,
  results,
  notAvailable,
  country,
}: I_TotalButtonProps): ReactElement => {
  const initialLink = getLink(store)
  let totalBooks = 0

  if (initialLink === null) {
    return <Button disabled>{notAvailable}</Button>
  }

  const total = results.reduce((acc: number, curr: any) => {
    if (curr[store]?.price) {
      acc += +curr[store].price
      totalBooks += 1
    }

    return acc
  }, 0)

  let link = results.reduce((acc: string, curr: any, index: number) => {
    if (store === "amazonEs" || (store === "amazonIt" && curr[store])) {
      const element = index + 1
      acc += `&ASIN.${element}=${curr.isbn10}&Quantity.${element}=1`
    }

    if (store === "casaDelLibro" && curr[store]) {
      if (index === 0) {
        acc += `${curr.isbn13}-1`
      } else if (index !== results.length - 1) {
        acc += `-${curr.isbn13}-1`
      } else {
        acc += `-${curr.isbn13}-1)`
      }
    }

    if (store === "imosver" && curr[store]) {
      if (index === 0) {
        acc += curr.isbn13
      } else {
        acc += `,${curr.isbn13}`
      }
    }

    return acc
  }, initialLink)

  if (store === "imosver") {
    link += "&afiliado=367"
  }

  return (
    <Button
      className="aggregate-results__button"
      onClick={() => {
        trackCustomEvent({
          category: `Buy All Books Button - ${store}`,
          action: "Click",
          label: link,
        })
        window.open(link, LinkTarget.Blank)
      }}
    >
      {getPrice(total, country)} ({totalBooks})
    </Button>
  )
}

const AggregateResultsComponent: FC<I_Props> = ({
  title,
  text,
  country,
  results,
  stores,
  notAvailable,
  booksListText,
}: I_Props): ReactElement => {
  const getAvailableCodes = (storeName: string): string => {
    let isbnCodes: string[] = []
    results.forEach((result: any) => {
      if (result[storeName]) {
        isbnCodes.push(result.isbn13)
      }
    })

    return isbnCodes.join(", ")
  }

  const hasResults = (store: string): boolean => {
    const total = results.reduce((acc: number, curr: any) => {
      if (curr[store]?.price) {
        return acc + 1
      }

      return acc
    }, 0)

    return total === 0 ? false : true
  }

  return (
    <div className="aggregate-results">
      <Title level={2} className="aggregate-results__title">
        {title}
      </Title>
      <ReactMarkdown children={text} />
      <Collapse>
        {stores
          .filter(
            ({ name }) => Object.keys(STORES).includes(name) && hasResults(name)
          )
          .map(({ name, link }: I_Store) => (
            <Panel
              header={link.text}
              key={name}
              extra={
                <TotalButton
                  store={name}
                  results={results}
                  notAvailable={notAvailable}
                  country={country}
                />
              }
            >
              <div>
                <Text strong>{booksListText}</Text>
                <Text type="secondary"> {getAvailableCodes(name)}</Text>
              </div>
            </Panel>
          ))}
      </Collapse>
    </div>
  )
}

export default AggregateResultsComponent
