import React, { ReactElement, FC, useState, useEffect } from "react"
import { Row, Col, Typography, Spin, Tag } from "antd"
import ReactMarkdown from "react-markdown"
import useSWR from "swr"

import { I_Store } from "../../global/interfaces"
import { getWindowLocationSearchObj } from "../../global/utils/window"
import { SearchType } from "../../global/enums"
import { postJsonData } from "../../services/api"
import StoresResult from "../stores-result/stores-result"
import AggregateResults from "../aggregate-results/aggregate-results"

import "./search-results.less"

enum State {
  Processing = "processing",
  Success = "success",
  Error = "error",
}

const STORES = {
  ES: ["amazonEs", "casaDelLibro", "imosver", "agapea"],
  IT: ["amazonIt", "mondadori", "libraccio", "feltrinelli"],
}

const { Title, Text } = Typography
interface I_Props {
  title: string
  text: string
  titleSearch: string
  authorSearch: string
  isbnSearch: string
  notAvailable: string
  noResults: string
  agregateTitle: string
  agregateText: string
  stores: I_Store[]
  booksListText: string
  baseUrl: string
}

interface I_SearchParams {
  type: string
  value: string
  country?: "es" | "it"
}

const SearchResultsComponent: FC<I_Props> = ({
  title,
  text,
  agregateTitle,
  agregateText,
  titleSearch,
  authorSearch,
  isbnSearch,
  stores,
  notAvailable,
  booksListText,
  baseUrl,
}: I_Props): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [postJsonDataParams, setPostJsonDataParams] = useState<any>(null)
  const [results, setResults] = useState<any>([])
  const [, countryFromPathname] = baseUrl.split("/")
  const searchParams = getWindowLocationSearchObj() || {
    type: "",
    value: "",
    country: "",
  }
  const { type, value, country }: I_SearchParams = searchParams
  const { data, error } = useSWR(postJsonDataParams, postJsonData)
  const [searchItems, setSearchItems] = useState<any>(
    type === SearchType.ISBN
      ? value.split(",").map((code: string) => {
          return { text: code, state: State.Processing }
        })
      : [{ text: decodeURIComponent(value), state: State.Processing }]
  )

  let countryStores: any
  if (country === "es" || countryFromPathname === "es") {
    countryStores = STORES.ES.map(storeName =>
      stores.find(({ name }) => name === storeName)
    )
  } else if (country === "it" || countryFromPathname === "it") {
    countryStores = STORES.IT.map(storeName =>
      stores.find(({ name }) => name === storeName)
    )
  }

  const searchType =
    type === SearchType.ISBN
      ? isbnSearch
      : type === SearchType.Author
      ? authorSearch
      : titleSearch

  useEffect(() => {
    const url = `${process.env.GATSBY_LETTURLIA_API}/books`
    setPostJsonDataParams([
      url,
      { [type]: value, country: country || countryFromPathname },
    ])
  }, [])

  useEffect(() => {
    if (data) {
      if (type === SearchType.ISBN) {
        setSearchItems(
          searchItems.map(({ text }: any) => {
            const match = data.find(
              ({ isbn10, isbn13 }: any) => text === isbn13 || text === isbn10
            )
            if (match) {
              return { text, state: State.Success }
            }
            return { text, state: State.Error }
          })
        )
      }
      setResults(data)
      setIsLoading(false)
    }
    if (error) {
      setIsLoading(false)
    }
  }, [data, error])

  return (
    <Row justify="center" className="search-results" gutter={24}>
      {isLoading && <Spin size="large" />}
      <Col span={24} className="search-results__header">
        <Title className="search-results__title">{title}</Title>
        <ReactMarkdown children={text} />
        <div className="search-results__type">
          <Text className="search-results__type-name">{searchType}:</Text>
          <div>
            {searchItems.map(({ text, state }: any, index: number) => (
              <Tag key={`${text}-${index}`} color={state}>
                {text}
              </Tag>
            ))}
          </div>
        </div>
      </Col>
      {type === SearchType.ISBN && results.length > 1 && (
        <Col xs={24} lg={8} className="search-results__aggregate">
          <AggregateResults
            title={agregateTitle}
            text={agregateText}
            country={country || countryFromPathname}
            results={results}
            stores={countryStores}
            notAvailable={notAvailable}
            booksListText={booksListText}
          />
        </Col>
      )}
      {type === SearchType.ISBN && results.length > 1 ? (
        <Col xs={24} lg={type === SearchType.ISBN ? 16 : 24}>
          {results.map((result: any) => (
            <StoresResult
              result={result}
              stores={countryStores}
              key={result._id}
              titleSearch={titleSearch}
              authorSearch={authorSearch}
              isbnSearch={isbnSearch}
              notAvailable={notAvailable}
              country={country || countryFromPathname}
            />
          ))}
        </Col>
      ) : (
        results.map((result: any) => (
          <Col xs={24} lg={type === SearchType.ISBN ? 20 : 12} key={result._id}>
            <StoresResult
              result={result}
              stores={countryStores}
              key={result._id}
              titleSearch={titleSearch}
              authorSearch={authorSearch}
              isbnSearch={isbnSearch}
              notAvailable={notAvailable}
              country={country || countryFromPathname}
            />
          </Col>
        ))
      )}
    </Row>
  )
}

export default SearchResultsComponent
